<template>
  <!-- 新增患病-->
  <div
      class="contain"
      :style="{
      width: projectId === '' ? 'calc(100% - 3.33333vw)' : '100%',
      'padding-top': projectId === '' ? '1.77083vw' : '0',
      'overflow-y': projectId === '' ? '' : 'auto',
      height: projectId === '' ? '' : '41.66667vw',
    }"
      v-loading.fullscreen.lock="fullPageLoading"
  >
    <div class="header-title" v-if="projectId === ''">
      <!-- <span>{{ pageTitle }}</span>
      <el-button class="return-button" @click="onCancel">返回</el-button> -->
      <el-page-header @back="onCancel" :content="pageTitle">
        <template slot="title">
          <i class="el-icon-arrow-left"></i><span>返回</span>
        </template>
      </el-page-header>
    </div>
    <div class="form">
      <el-form
          :model="superviseProjectForm"
          style="width: 80%"
          :rules="type != 'view' ? rules : {}"
          ref="superviseProjectForm"
          :label-width="labelWidth"
          class="demo-ruleForm"
          :disabled="type == 'view'"
      >
        <el-form-item label="父级节点:" prop="parentId" ref="parentId">
          <el-cascader
              class="elInput form-select-class"
              v-model="levelValue"
              :props="levelProps"
              :options="levelOptions"
              clearable
              :show-all-levels="false"
              @change="handleLevelChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="项目名称:" prop="projectName" ref="projectName">
          <el-input
              v-model.trim="superviseProjectForm.projectName"
              class="elInput"
              :maxlength="100"
              placeholder="请输入项目名称"
              clearable
          ></el-input>
        </el-form-item>
        <el-form-item
            label="项目要求:"
            prop="projectRequire"
            ref="projectRequire"
        >
          <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入项目要求"
              v-model="superviseProjectForm.projectRequire"
              resize="none"
              :maxlength="200"
          ></el-input>
        </el-form-item>
        <el-form-item
            label="督导方式:"
            prop="supervisionMethod"
            ref="supervisionMethod"
        >
          <el-checkbox-group v-model="superviseProjectForm.supervisionMethod">
            <el-checkbox :label="'1'">远程督导</el-checkbox>
            <el-checkbox :label="'2'">现场督导</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="排序:">
          <el-input
              v-model="superviseProjectForm.sort"
              class="elInput"
              v-input-number
              @keyup.native="
              getInputChange(superviseProjectForm.sort, 'form', 'sort')
            "
              :maxlength="3"
              placeholder="请输入排序"
              clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="是否启用:" prop="isEnable" ref="isEnable">
          <el-radio-group v-model="superviseProjectForm.isEnable">
            <el-radio :label="'1'">启用</el-radio>
            <el-radio :label="'0'">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="dashed-line"></div>
        <el-form-item label="评分细则:" prop="type" ref="type">
          <el-radio-group
              v-model="superviseProjectForm.type"
              @change="handleTaskType"
          >
            <el-radio :label="'1'">得分题</el-radio>
            <el-radio :label="'2'">扣分题</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="supProScoreList" ref="supProScoreList">
          <!-- <el-radio-group v-model="superviseProjectForm.superviseProjectStatus">
            <el-radio :label="'1'">启用</el-radio>
            <el-radio :label="'0'">禁用</el-radio>
          </el-radio-group> -->
          <table class="project-task-list">
            <thead class="project-task-title">
            <th class="project-task-title-th">选项(单选)</th>
            <th
                class="project-task-title-th"
                :style="{
                  width: superviseProjectForm.type === '1' ? '6.25vw' : '7.29167vw',
                }"
            >
              分数
            </th>
            <th class="project-task-title-th"></th>
            </thead>
            <tbody class="project-task-body">
            <tr
                class="project-task-body-tr"
                v-for="(item, i) in projectTaskList"
                :key="i"
            >
              <td class="project-task-body-td">
                <el-input
                    type="textarea"
                    v-model="item.scoreName"
                    :maxlength="200"
                    clearable
                    autosize
                ></el-input>
              </td>
              <td class="project-task-body-td">
                  <span
                      v-if="superviseProjectForm.type === '2'"
                      style="margin-right: 0.625vw"
                  >-</span
                  >
                <el-input
                    v-model="item.score"
                    v-input-number
                    @keyup.native="getInputChange(item.score, 'list', i, item)"
                    style="width: 4.16667vw; margin-right: 0.625vw"
                    :maxlength="3"
                    clearable
                ></el-input>
                <span>分</span>
              </td>
              <td class="project-task-body-td">
                <img
                    :src="delIcon"
                    alt=""
                    @click="handleTaskFun('del', item, i)"
                    v-if="type != 'view'"
                />
              </td>
            </tr>
            </tbody>
          </table>
          <div
              class="task-add-button"
              @click="handleTaskFun('add')"
              v-if="type != 'view' && projectTaskList.length < 5"
          >
            <img :src="taskAddIcon" alt=""/>
            <span>添加选项</span>
          </div>
        </el-form-item>
        <div class="dashed-line"></div>
        <div class="form-title">评分资料：</div>
        <el-form-item label="是否必填:" prop="isEvaluationInformation">
          <el-radio-group
              v-model="superviseProjectForm.isEvaluationInformation"
          >
            <el-radio :label="'1'">必填</el-radio>
            <el-radio :label="'0'">可选</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="帮助描述:">
          <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入帮助描述"
              v-model="superviseProjectForm.helpDescription"
              :maxlength="200"
              resize="none"
          ></el-input>
        </el-form-item>
        <el-form-item label="附件类型限制:" prop="isAttachmentTypes">
          <el-switch
              v-model="superviseProjectForm.isAttachmentTypes"
              @change="handleFileLimit"
              active-color="#003685"
              inactive-color="#C5C3D8"
              :active-text="!superviseProjectForm.isAttachmentTypes ? '否' : '是'"
              :inactive-text="''"
          ></el-switch>
        </el-form-item>
        <el-form-item
            label="附件类型:"
            prop="informationType"
            v-if="superviseProjectForm.isAttachmentTypes"
            ref="informationType"
        >
          <el-checkbox-group v-model="superviseProjectForm.informationType">
            <el-checkbox
                v-for="item in fileTypeList"
                :key="item.value"
                :label="item.value"
            >{{ item.name }}
            </el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div class="form-btn" v-if="type != 'view'">
        <el-button class="cancel" :loading="buttonloading" @click="onCancel"
        >取消
        </el-button
        >
        <el-button class="submit" :loading="buttonloading" @click="onSubmit"
        >保存
        </el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import delIcon from "@/assets/img/del-icon.png"; //删除icon
import taskAddIcon from "@/assets/img/task-add-icon.png"; //删除icon
export default {
  name: "superviseProjectDetail",
  props: {
    projectId: {
      type: String,
      default: "",
    },
    showType: {
      type: String,
      default: "",
    },
  },
  data() {
    var validateProjectList = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请添加评分选项"));
      } else if (value.length > 0) {
        let isAllWrite = value.every((item) => {
          return item.score !== "" && item.scoreName !== "";
        });
        let isHaveOne = value.some((item) => {
          return Number(item.score) === 0 && this.superviseProjectForm == "2";
        });
        if (!isAllWrite) {
          callback(new Error("有评分选项没有填写完整，请输入"));
        } else {
          callback();
        }
        if (this.superviseProjectForm.type == "2" && !isHaveOne) {
          callback(new Error("评分选项中必须有一项值为0，请修改"));
        } else {
          callback();
        }
      }
    };
    return {
      id: null,
      superviseProjectForm: {
        parentId: "", //父级节点
        projectName: "", //项目名称
        projectRequire: "", //项目要求
        supervisionMethod: [], //督导方式
        sort: "0", //排序
        isEnable: "1", //是否启用
        type: "1", //评分项目
        supProScoreList: [], //评分项目选项list
        isEvaluationInformation: "1", //评分资料是否必填
        helpDescription: "", //帮助描述
        isAttachmentTypes: false, //附件类型限制
        informationType: [], //附件类型
      },
      rules: {
        parentId: [
          {required: true, message: "请选择父级节点", trigger: "change"},
        ],
        projectName: [
          {required: true, message: "请输入项目名称", trigger: "blur"},
        ],
        projectRequire: [
          {required: true, message: "请输入项目要求", trigger: "blur"},
        ],
        supervisionMethod: [
          {
            required: true,
            type: "array",
            message: "请至少选择一种督导方式",
            trigger: "change",
          },
        ],
        isEnable: [
          {required: true, message: "请选择是否启用", trigger: "change"},
        ],
        type: [
          {required: true, message: "请选择评分项目类型", trigger: "change"},
        ],
        supProScoreList: [
          {validator: validateProjectList, trigger: "change"},
        ],
        isAttachmentTypes: [
          {required: true, message: "请选择附件类型限制", trigger: "change"},
        ],
        isEvaluationInformation: [
          {
            required: true,
            message: "请选择评分资料是否必填",
            trigger: "change",
          },
        ],
        informationType: [
          {required: true, message: "请选择附件类型", trigger: "change"},
        ],
      },
      type: "", //编辑类型
      jumpType: "", //跳转类型
      pageTitle: "", //页面标题
      returnParentInfo: {}, // 返回的级联数据

      projectTaskList: [], //评分选项list
      levelOptions: [], //父级节点数据
      levelProps: {
        value: "id",
        label: "projectName",
        children: "supProList",
      }, //父级节点props
      levelValue: "",

      userInfo: {}, //登录用户信息

      buttonloading: false, //按钮loading
      fullPageLoading: false, //页面全局loading

      delIcon, // 删除icon
      taskAddIcon, //添加题目icon

      labelWidth: "140px",
    };
  },
  computed: {
    ...mapState({
      fileTypeList: (state) => state.common.fileTypeList,
    }),
  },
  //路由进入前改变title
  beforeRouterEnter(to, from, next) {
    if (to.query.type == "edit") {
      to.meta.title = "督导项目编辑";
    } else if (to.query.type == "add") {
      to.meta.title = "督导项目新增";
    } else {
      to.meta.title = "督导项目查看";
    }
    next();
  },
  created() {
    let userInfo = sessionStorage.getItem("userInfo")
        ? JSON.parse(sessionStorage.getItem("userInfo"))
        : {};
    this.userInfo = userInfo;

    if (this.$route.query.type == "edit") {
      this.pageTitle = "督导项目编辑";
    } else if (this.$route.query.type == "add") {
      this.pageTitle = "督导项目新增";
    } else {
      this.pageTitle = "督导项目查看";
    }

    let chooseMenuInfoList = sessionStorage.getItem("chooseMenuInfoList")
        ? JSON.parse(sessionStorage.getItem("chooseMenuInfoList"))
        : null;
    if (chooseMenuInfoList) {
      chooseMenuInfoList.map((item) => {
        if (item.path == this.$route.path) {
          item.meta.title = this.pageTitle;
        }
      });
      let breadCrumbList = JSON.parse(JSON.stringify(chooseMenuInfoList));
      breadCrumbList.shift();
      console.log(breadCrumbList);
      this.$store.commit("common/getBreadcrumbList", breadCrumbList || []);
    }

    let info = sessionStorage.getItem("nodeInfo");
    if (info) {
      info = JSON.parse(info);
      this.returnParentInfo = {id: info.checkNode[0]};
    }
    this.type = this.$route.query.type;
    this.jumpType = this.$route.query.jumpType;
    this.id = this.$route.query.id;
    this.fullPageLoading = true;
    this.getLevelOptions();
  },
  mounted() {
    window.addEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "100px";
      } else {
        this.labelWidth = (140 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
    if (window.innerWidth < 1300) {
      this.labelWidth = "100px";
    } else {
      this.labelWidth = (140 / 1920) * window.innerWidth + "px";
    }
  },
  watch: {
    projectTaskList: {
      immediate: true,
      handler(val) {
        console.log(val);
        this.superviseProjectForm.supProScoreList = JSON.parse(
            JSON.stringify(val)
        );
      },
      deep: true,
    },
    showType(val) {
      this.type = val;
      console.log(val);
    },
  },
  methods: {
    //获取父级节点信息
    getLevelOptions() {
      this.levelOptions = [];
      let url = "";
      let param = {};
      if (this.jumpType == "headquarters") {
        url = "getProjectTree";
        param.showLevel = "1";
      } else if (this.jumpType == "company") {
        url = "getBranchTree";
        param.branchId = this.userInfo.schoolList[0].companyLabel;
      }
      this.$api[url](param)
          .then((res) => {
            if (res.data.code == 0) {
              console.log(res.data.data);
              let list = res.data.data;
              // this.levelOptions = list
              let resultList = this.isHaveLevelFourList(list);
              console.log(resultList);
              resultList.map((item) => {
                if (item.isSave) {
                  if (item.deleteIndexList.length == 0) {
                    delete item.deleteIndexList;
                    this.levelOptions.push(item);
                  } else {
                    let supList = [];
                    item.supProList.map((info, i) => {
                      let index = item.deleteIndexList.findIndex((indexInfo) => {
                        return indexInfo == i;
                      });
                      if (index == -1) {
                        supList.push(info);
                      }
                    });
                    item.supProList = supList;
                    delete item.deleteIndexList;
                    this.levelOptions.push(item);
                  }
                }
              });
              this.$nextTick(() => {
                if (this.type == "edit" || this.type == "view") {
                  this.getDetailInfo();
                } else if (this.returnParentInfo.id) {
                  this.fullPageLoading = false;
                  this.levelValue = this.getCascaderList(
                      this.returnParentInfo.id
                  );
                  this.superviseProjectForm.parentId = this.returnParentInfo.id;
                } else {
                  this.fullPageLoading = false;
                }
              });
            } else {
              this.fullPageLoading = false;
              this.$message.error(res.data.msg);
            }
          })
          .catch(() => {
            this.fullPageLoading = false;
          });
    },
    //获取详情数据
    getDetailInfo() {
      this.$api
          .getProjectInfoById(this.projectId ? this.projectId : this.id)
          .then((res) => {
            this.fullPageLoading = false;
            if (res.data.code == 0) {
              console.log(res.data.data);
              let result = res.data.data;
              this.superviseProjectForm = {
                parentId: result.parentId, //父级节点
                projectName: result.projectName, //项目名称
                projectRequire: result.projectRequire, //项目要求
                supervisionMethod: result.supervisionMethod
                    ? result.supervisionMethod.split(",")
                    : [], //督导方式
                sort: result.sort || 0, //排序
                isEnable: result.isEnable || "1", //是否启用
                type: result.type || "1", //评分项目
                supProScoreList: result.supProScoreList
                    ? result.supProScoreList
                    : [], //评分项目选项list
                isEvaluationInformation: result.isEvaluationInformation || "1", //评分资料是否必填
                helpDescription: result.helpDescription, //帮助描述
                isAttachmentTypes:
                    result.isAttachmentTypes === "1" ? true : false, //附件类型限制
                informationType: result.informationType
                    ? result.informationType.split(",")
                    : [], //附件类型
              };
              this.levelValue = this.getCascaderList(result.parentId);
              this.projectTaskList = result.supProScoreList
                  ? result.supProScoreList
                  : [];
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch(() => {
            this.fullPageLoading = false;
          });
    },
    //获取级联默认选项
    getCascaderList(id) {
      let list = [];
      console.log(this.levelOptions);
      this.levelOptions.map((item) => {
        if (item.supProList && item.supProList.length > 0) {
          item.supProList.map((info) => {
            if (info.supProList && info.supProList.length > 0) {
              info.supProList.map((threeInfo) => {
                if (id == threeInfo.id) {
                  list = [item.id, info.id, threeInfo.id];
                }
              });
            }
          });
        }
      });
      return list;
    },
    //获取数据
    isHaveLevelFourList(list) {
      let resultList = null;
      list.map((item) => {
        item.isSave = false;
        item.deleteIndexList = [];
        if (item.supProList && item.supProList.length > 0) {
          item.supProList.map((info, i) => {
            if (info.supProList && info.supProList.length > 0) {
              info.supProList.map((threeInfo) => {
                item.isSave = true;
                delete threeInfo.supProList;
              });
            } else {
              item.deleteIndexList.push(i);
              delete info.supProList;
            }
          });
          if (item.deleteIndexList.length == item.supProList.length) {
            item.isSave = false;
          } else {
            item.isSave = true;
          }
        } else {
          item.isSave = false;
          delete item.supProList;
        }
      });
      resultList = list;
      return resultList;
    },
    //取消
    onCancel() {
      this.$router.back(-1);
    },
    //处理项目题目
    handleTaskFun(type, item, i) {
      let length = this.projectTaskList.length;
      console.log(length);
      if (type == "add") {
        let info = {
          scoreName: "",
          score: "",
        };
        if (this.type == "edit") {
          info.scoreId = "";
        }
        this.projectTaskList.push(info);
      } else if (type == "del") {
        this.projectTaskList.splice(i, 1);
      }
    },
    //改变评分类型
    handleTaskType(val) {
    },
    //改变附件类型限制
    handleFileLimit(val) {
      if (!val) {
        this.superviseProjectForm.informationType = [];
      }
    },
    //选择父级节点
    handleLevelChange(val) {
      console.log(val);
      if (val.length == 3) {
        this.superviseProjectForm.parentId = val[2];
      }
    },
    //改变输入按键事件
    getInputChange(value, type, valueType, item) {
      let resultValue = "";
      if (type == "list") {
        resultValue = value.replace(/[^\w\.\/]/gi, "");
        let info = {...item};
        info.score = resultValue;
        this.$set(this.projectTaskList, valueType, info);
      } else if (type == "form") {
        resultValue = value.replace(/[^\w\.\/]/gi, "");
        this.superviseProjectForm[valueType] = resultValue;
      }
    },
    //提交
    onSubmit() {
      this.$refs.superviseProjectForm.validate((valid, object) => {
        if (valid) {
          this.buttonloading = true;
          console.log(this.superviseProjectForm);
          let params = JSON.parse(JSON.stringify(this.superviseProjectForm));
          console.log(this.levelValue);
          params.showLevel = this.jumpType == "headquarters" ? "1" : "2";
          params.orgId = this.jumpType == "headquarters" ? "" : "";
          params.orgName = this.jumpType == "headquarters" ? "" : "";
          params.supervisionMethod = params.supervisionMethod.join(",");
          params.informationType = params.informationType.join(",");
          params.isAttachmentTypes = params.isAttachmentTypes ? "1" : "0";
          let url = "";
          if (this.type == "edit") {
            url = "/supervise/project/updateById";
            params.id = this.id;
          } else {
            url = "/supervise/project/save";
          }
          this.$api
              .handleProjectFun(url, params)
              .then((res) => {
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message:
                        this.type == "edit"
                            ? "编辑督导项目成功!"
                            : "新增督导项目成功!",
                    duration: 2000,
                    onClose: () => {
                      this.$router.back(-1);
                    },
                  });
                } else {
                  this.buttonloading = false;
                  this.$message.error(res.data.msg);
                }
              })
              .catch(() => {
                this.buttonloading = false;
              });
        } else {
          console.log(object);

          for (let i in object) {
            let dom = this.$refs[i];
            dom.$el.scrollIntoView({
              //滚动到指定节点
              block: "center", //值有start,center,end，nearest，当前显示在视图区域中间
              behavior: "smooth", //值有auto、instant,smooth，缓动动画（当前是慢速的）
            });
            break;
          }
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  beforeDestroy() {
    sessionStorage.removeItem("nodeInfo");
    window.removeEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "100px";
      } else {
        this.labelWidth = (140 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
  },
};
</script>

<style lang="scss" scoped>
.contain {
  width: calc(100% - 64px);
  padding: 34px 30px 30px 30px;

  .header-title {
    width: 100%;
    color: #3d3e4f;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .return-button {
      &.el-button:focus {
        color: #222222;
        border-color: #b5bdcf;
        background-color: rgba(52, 85, 236, 0.1);
      }

      &.el-button:hover {
        color: #222222;
        border-color: #b5bdcf;
        background-color: rgba(52, 85, 236, 0.1);
      }
    }
  }

  /deep/ .el-radio__input.is-checked .el-radio__inner {
    border-color: #003685;
    background: #003685;
  }

  /deep/ .el-radio__input.is-checked + .el-radio__label {
    color: #003685;
  }

  /deep/ .el-radio {
    font-size: 14px;
    font-weight: 400;
    color: #010334;
  }

  .dashed-line {
    width: 100%;
    height: 0;
    border-bottom: 1px dashed #e2e7f0;
    margin: 40px 0;
  }

  .form {
    width: calc(100% - 80px);
    padding: 0 40px;
    min-height: 696px;

    .form-title {
      width: 100%;
      color: #3d3e4f;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 40px;
    }

    /deep/ .el-form-item .el-select {
      width: 100%;
      height: 40px;
    }

    /deep/ .el-form-item__label {
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      color: #606266;
    }

    /deep/ .el-input--suffix .el-input__inner {
      padding-right: 30px;
    }

    /deep/ .el-input--suffix .el-input__inner,
    /deep/ .el-textarea__inner {
      font-size: 14px;
      font-weight: 400;
      color: #010334;
    }

    /deep/ .el-textarea__inner {
      height: 80px;
    }

    /deep/ .el-switch__label.is-active {
      color: #010334;
    }

    /deep/ .el-switch__label {
      color: #010334;
    }

    /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #003685;
    }

    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #003685 !important;
      border-color: #003685 !important;
    }

    /deep/ .el-checkbox__inner:hover {
      border-color: #003685 !important;
    }

    /deep/ .el-checkbox__label {
      color: #010334 !important;
    }
  }

  .form-btn {
    margin-top: 43px;
    display: flex;
    padding-left: 130px;

    .cancel {
      width: 137px;
      height: 40px;
      border: 1px solid #b5bdcf;
      border-radius: 4px;
      font-size: 14px;
      line-height: 14px;
      color: #222222;
      opacity: 1;
    }

    .submit {
      display: block;
      width: 137px;
      height: 40px;
      background: #003685;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    /deep/ .el-button + .el-button {
      margin-left: 20px;
    }
  }

  .project-task-list {
    width: 100%;

    .project-task-title {
      width: 100%;
      background-color: #f1f4f9;

      .project-task-title-th {
        padding: 0;
        padding-left: 16px;
        min-width: 100px;
        height: 48px;
        line-height: 48px;
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        color: #5e6d9b;

        &:first-child {
          border-top-left-radius: 12px;
        }

        &:last-child {
          border-top-right-radius: 12px;
        }
      }
    }

    .project-task-body {
      width: 100%;

      .project-task-body-tr {
        padding: 0;
        width: 100%;

        .project-task-body-td {
          padding: 12px 0 0 16px;
          min-width: 100px;
          color: #b5bdcf;
          font-weight: 400;
          font-size: 14px;

          &:first-child {
            padding-left: 0;
          }

          img {
            width: 16px;
            height: 16px;
            vertical-align: middle;
            cursor: pointer;
          }

          span {
            vertical-align: middle;
          }
        }
      }
    }
  }

  .task-add-button {
    width: 96px;
    height: 26px;
    border: 1px solid #b5bdcf;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    color: #66708d;
    margin-top: 12px;
    cursor: pointer;

    img {
      width: 13px;
      height: 13px;
      margin-right: 6px;
    }
  }
}
</style>
